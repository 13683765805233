import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { share } from 'rxjs/operators';

@Component({
  selector: 'social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.css'],
  encapsulation:ViewEncapsulation.None,
})
export class SocialMediaComponent implements OnInit  {

  @Input('number') number:number = 0;
  @Input('url') url:string;
  @Input('type') type:string; 
  public config = {
    animation: 'count', 
    format: 'd', 
    theme: 'minima', 
    value: 0,
    auto: false,
} ;
isOdoVisible:boolean = true;
public formatedNumber = "";
public formatedNumberText = "";
isTimeOut = false;
public observable: Observable<boolean>;
private observer : Observer<boolean>;
  constructor() {
    this.observable = new Observable<boolean>((observer)=>this.observer = observer).pipe(share());
   }

  ngOnInit() { 
  }


  onMouseOver()
  {
  
    if(!this.isTimeOut)
    {
      this.observer.next(true);
      setTimeout(()=>{this.isTimeOut = true;this.formatNumberInText(); this.isOdoVisible = false;},3000);
    }
  }

  formatNumberInText () : void
  {
    if(this.number <1000)
    {
      this.formatedNumber = this.number+"";
      console.log(this.formatedNumber);
    }
    else if(this.number >= 1000 && this.number < 10000)
    {
      this.formatedNumberText = "k";
      this.formatedNumber = this.number +"";
      if(this.formatedNumber[1] === "0")
      {
        this.formatedNumber = this.formatedNumber[0];
      }
      else
      {
        this.formatedNumber = this.formatedNumber[0]+"."+this.formatedNumber[1]+"+";
      }
    }
    else if (this.number >=10000 && this.number <100000)
    {
      this.formatedNumber = this.number+"";
      this.formatedNumberText = "k";
      this.formatedNumber = this.formatedNumber[0]+""+this.formatedNumber[1]+"+";
    }
    else if(this.number >= 100000 && this.number < 1000000)
    {
      this.formatedNumber = this.number+"";
      this.formatedNumberText = "k";
      this.formatedNumber = this.formatedNumber[0]+""+this.formatedNumber[1]+this.formatedNumber[2]+"+";
    }
    else if (this.number >= 1000000)
    {
      this.formatedNumber = this.number+"";
      this.formatedNumberText = "M";
      this.formatedNumber = this.formatedNumber[0];
    }
  }

  onSocialClick()
  {
    if(this.isTimeOut)
    window.open(this.url,"blank");
  }


}
