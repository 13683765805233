import { Component ,AfterViewInit,OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Observer } from 'rxjs';
import {share} from 'rxjs/operators';
import { DataServiceService } from './services/data-service.service';
import {Platform} from '@angular/cdk/platform'
import { MatBottomSheetRef, MatBottomSheet } from '@angular/material';
import { DownloadBottomSheetComponent } from './download-bottom-sheet/download-bottom-sheet.component';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit , OnInit{
  title = 'blush';
 
  fbFanCount:number; 
  fbUrl = this.platform.ANDROID || this.platform.IOS ? "https://m.facebook.com/Blush.sd":"https://facebook.com/Blush.sd";
  
  instaUrl = "https://instagram.com/blush_sd";
  twitterUrl = "https://twitter.com/blush_store1";
  gplayUrl = "https://play.google.com/store/apps/details?id=com.blush.sd";
  appleUrl = "https://apps.apple.com/us/app/id1528909119";

  brandSrc = "assets/imgs/gp-en.png";
  gpInstallsCount:number;
  isgpVisible:boolean = false;
  instaFollowers:number;
  isInstaVisible:boolean = false;
  isAppleVisible:boolean = false;
  appleInstallsCount;

  public config = {
    animation: 'count', 
    format: 'd', 
    theme: 'minima', 
    value: 0,
    auto: false,
}           

  constructor(public translate : TranslateService,public data:DataServiceService,public platform:Platform, private bottomSheet:MatBottomSheet)
  {
    translate.addLangs(['ar','en']);
    translate.setDefaultLang('en');
    let defaultLang = translate.getBrowserLang() === 'ar'? 'ar':'en';
    translate.use(defaultLang);
    this.translate.onLangChange.subscribe((lang)=>{
      this.brandSrc = lang.lang === "ar"?"assets/imgs/gp-ar.png":"assets/imgs/gp-en.png";
    })
    setTimeout(()=>{
      if(platform.ANDROID || platform.IOS)
      this.bottomSheet.open(DownloadBottomSheetComponent);
    },30000);
  }
  ngOnInit() {
   
  }


  ngAfterViewInit(): void {
    this.data.getFbFollowersCount().subscribe(
      (json)=>{
        console.log('json ' ,json.fan_count);
        this.fbFanCount = json.fan_count + 0;
      },
      (error)=>
      {
        console.log('error ',error);
        this.fbFanCount  = 5000;
      },
    );

    this.data.getGpInstalls().subscribe(
      (result)=>{
        this.gpInstallsCount = result.maxInstalls;
        this.isgpVisible = true;
      }
    );

    this.data.getAppleInstalls().subscribe(
      (result)=>{
        this.appleInstallsCount = result.maxInstalls ;
        this.isAppleVisible = true;
      }
    );
    this.data.getInstaFollowers().subscribe(((res)=>{
      this.instaFollowers = res.followers;
      this.isInstaVisible = true;
    }));

  }

  opengPlay()
  {
    window.open(this.gplayUrl,'blank');
  }
  openAppleStore()
  {
    window.open(this.appleUrl,'blank');
  }
}
