import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'download-bottom-sheet',
  templateUrl: './download-bottom-sheet.component.html',
  styleUrls: ['./download-bottom-sheet.component.css']
})
export class DownloadBottomSheetComponent implements OnInit {
  appText:string = "";
  downloadText:string = "";
  constructor(private bottomSheetRef:MatBottomSheetRef<DownloadBottomSheetComponent>,public platform:Platform,public translate : TranslateService) {
    if(platform.ANDROID)
    {
      translate.get('androidAppText').subscribe((text:string)=>{
        this.appText = text;
      });
      translate.get('androidDownloadText').subscribe((text:string)=>{
        this.downloadText = text;
      });
    }
    if(platform.IOS)
    {
      translate.get('iosAppText').subscribe((text:string)=>{
        this.appText = text;
      });
      translate.get('iosDownloadText').subscribe((text:string)=>{
        this.downloadText = text;
      });
    }
   }

  ngOnInit() {
  }
  onDownloadButton()
  {
    if(this.platform.ANDROID) window.open('https://play.google.com/store/apps/details?id=com.blush.sd',"blank");
    if(this.platform.IOS) window.open("https://m.facebook.com/Blush.sd");
  }
}
