import { Component, OnInit,AfterViewInit } from '@angular/core';
import { trigger, transition, keyframes, animate, style } from '@angular/animations';

@Component({
  selector: 'image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.css'],
  animations:[
    trigger('imageZoomIn',[
      transition('void => *',[
       animate('1s ease-in',keyframes([
         style({offset:0,opacity:0,transform:'scale(0,0)'}),
         style({offset:0.3,opacity:0.3,transform:'scale(0.3,0.3)'}),
         style({offset:0.5,opacity:0.5,transform:'scale(0.5,0.5)'}),
         style({offset:0.7,opacity:0.7,transform:'scale(0.7,0.7)'}),

         style({offset:1,opacity:1,transform:'scale(1,1)'})
      ])
       ),
      ]),

      transition('* => void',[
        animate('1s ease-out',keyframes([
         style({opacity:0.3,transform:'scale(0.3,0.3)',offset:.3}),
         style({opacity:0,transform:'scale(0,0)',offset:1})
        ]))
      ])
    ])
  ]
})
export class ImageSliderComponent implements OnInit , AfterViewInit{
  imgs = ['assets/imgs/5.png','assets/imgs/6.png','assets/imgs/3.png'];
  currentIndex = 0;
  constructor() { }

  ngAfterViewInit():  void{
    setInterval(()=>{
      if(this.currentIndex === this.imgs.length-1) this.currentIndex = 0;
      else this.currentIndex++;
    },4000);
  }

  ngOnInit() {
  }

}
