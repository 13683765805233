import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataServiceService {
  private fb_url = 'https://graph.facebook.com/Blush.sd?access_token='+environment.fbToken+'&fields=fan_count';
  constructor(private http:HttpClient) { 
  
  }

  getFbFollowersCount()
  {
   return this.http.get(this.fb_url).pipe(map((obj)=>{
     return JSON.parse(JSON.stringify(obj));
   }));
  }

  getGpInstalls()
  {
   return this.http.get(environment.host+"gplay").pipe(map((obj)=>{
    return JSON.parse(JSON.stringify(obj));
  }));
  }
  getInstaFollowers()
  {
   return this.http.get(environment.host+"insta").pipe(map((obj)=>{
    return JSON.parse(JSON.stringify(obj));
  }));
  }

  getAppleInstalls()
  {
    return this.http.get(environment.host+"apple").pipe(map((obj)=>{
      return JSON.parse(JSON.stringify(obj));
    }));
  }
}
