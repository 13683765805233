import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { AppbarComponent } from "./appbar/appbar.component";
import { ImageSliderComponent } from "./image-slider/image-slider.component";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  MatButtonModule,
  MatSelectModule,
  MatBottomSheetModule,
} from "@angular/material";
import { Ng2OdometerModule } from "ng2-odometer";
import { SocialMediaComponent } from "./social-media/social-media.component";
import { DownloadBottomSheetComponent } from "./download-bottom-sheet/download-bottom-sheet.component";
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
@NgModule({
  entryComponents: [DownloadBottomSheetComponent],
  declarations: [
    AppComponent,
    AppbarComponent,
    ImageSliderComponent,
    SocialMediaComponent,
    DownloadBottomSheetComponent,
  ],
  imports: [
    HttpClientModule,
    Ng2OdometerModule,
    BrowserAnimationsModule,
    BrowserModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatSelectModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
