import { Component, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Renderer3 } from '@angular/core/src/render3/interfaces/renderer';

@Component({
  selector: 'appbar',
  templateUrl: './appbar.component.html',
  styleUrls: ['./appbar.component.css']
})
export class AppbarComponent implements OnInit {

  constructor(public translate:TranslateService, private render :Renderer2) {
    
   }

  ngOnInit() {
  }

  onLangChange($event)
  {
    this.translate.use($event.value);
   if($event.value === 'ar') this.render.setStyle(document.body,'direction','rtl');
   else this.render.setStyle(document.body,'direction','ltr');
  }

  openEmail()
  {
    window.location.href = "mailto:contact@blush-sd.com?subject=Subject&body=message";
  }
}
